<template>
    <sf-sticker class="sf-sticker--additional">
        {{ t('title') }}
    </sf-sticker>
</template>

<script setup>
import SfSticker from '~/patterns/atoms/sticker/sticker';

const { t } = useI18n({
    useScope: 'local'
});
</script>

<i18n lang="json">
{
    "nl": {
        "title": "sluit bijna"
    },
    "en": {
        "title": "nearly closed"
    }
}
</i18n>

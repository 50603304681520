<template>
    <SfSticker>
        {{ t('title') }}
    </SfSticker>
</template>

<script setup>
import SfSticker from '~/patterns/atoms/sticker/sticker';

const { t } = useI18n({
    useScope: 'local'
});
</script>

<i18n lang="json">
{
    "nl": {
        "title": "nieuw"
    },
    "en": {
        "title": "new"
    }
}
</i18n>
